import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { MicrocmsStgNews } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const newsItemStyle = css`
  &:not(:first-of-type) {
    margin-top: 16px;
  }

  & > .button {
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
    transition: var(--hover-button-transition);
    border-radius: 16px;
    background: var(--color-background-bright);
    color: var(--color-text);
    text-decoration: none;

    &:hover {
      opacity: var(--hover-button-opacity);
    }
  }

  & > .button > .imagewrapper {
    width: 100%;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: 40%;
      margin-right: 32px;
    }
  }

  & > .button > .imagewrapper > .image {
    width: 100%;
    height: auto;
    border: 1px solid var(--color-bluegray50);
  }

  & > .button > .titlewrapper {
    width: 100%;
    margin-top: 14px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      width: calc(60% - 32px);
      margin-top: 0;
    }
  }

  & > .button > .titlewrapper > .category {
    display: inline-block;
    padding: 3px 7px 5px;
    border: 1px solid var(--color-background-key);
    border-radius: 4px;
    color: var(--color-background-key);
    font-size: ${rem(12)};
    line-height: 1.33;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-right: 24px;
    }
  }

  & > .button > .titlewrapper > .date {
    display: block;
    margin-top: 15px;
    font-size: ${rem(12)};
    font-weight: bold;
    letter-spacing: 1.2px;
    line-height: 1;
  }

  & > .button > .titlewrapper > .title {
    display: -webkit-box;
    margin-top: 24px;
    overflow: hidden;
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: -0.8px;
    line-height: 1.75;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  & > .button > .titlewrapper > .tagwrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    column-gap: 8px;
    row-gap: 4px;
  }

  & > .button > .titlewrapper > .tagwrapper > .tag {
    color: var(--color-text-sub);
    font-size: ${rem(12)};
    font-weight: bold;

    &::before {
      content: '#';
      padding-right: 2px;
    }
  }
`

const NewsItemComponent = ({ item, gatsbyImageData }: { item: MicrocmsStgNews; gatsbyImageData: IGatsbyImageData }) => {
  return (
    <li className={newsItemStyle}>
      <Link className="button" to={`/news/${item.stgNewsId}`}>
        <div className="imagewrapper">
          {item.title && <GatsbyImage className="image" image={gatsbyImageData} alt={item.title} />}
        </div>
        <div className="titlewrapper">
          {item.category && <span className="category">{item.category.name}</span>}
          <time className="date">{item.date}</time>
          <h2 className="title">{item.title}</h2>
          {item.tags && item.tags?.length > 0 && (
            <ul className="tagwrapper">
              {item.tags.map((tag) => {
                if (!tag) return null
                return (
                  <li className="tag" key={tag.id}>
                    {tag.name}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </Link>
    </li>
  )
}

export default NewsItemComponent
