import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import Pager from '~/components/Common/Pager'
import Banner from '~/components/News/Banner'
import Category from '~/components/News/Category'
import Item from '~/components/News/Item'
import Recommend from '~/components/News/Recommend'
import Tag from '~/components/News/Tag'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData } from '~/utils/helper'

interface NewsIndexProps {
  data: Query
  pageContext: {
    currentPage: number
    limit: number
    skip: number
    totalPage: number
    categoryContentId?: string
    categoryId?: string
    categoryName?: string
    tagContentId?: string
    tagId?: string
    tagName?: string
  }
}

const newsIndexStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .title {
    ${STYLE.MIXIN.CONTENT_TITLE}
  }

  & > .listwrapper {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
    padding-top: 32px;
    padding-right: 34px;
    padding-left: 34px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: grid;
      grid-template-areas:
        'list .'
        'list .'
        'list .'
        'list banner';
      grid-template-columns: 1fr 188px;
      grid-template-rows: repeat(3, auto) 1fr;
      padding-top: 80px;
      column-gap: 16px;
      row-gap: 16px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-template-columns: 1fr 236px;
      grid-template-rows: repeat(3, auto) 1fr;
      padding-right: 0;
      padding-left: 0;
    }
  }

  & > .listwrapper > .list {
    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: list;
    }
  }

  & > .listwrapper > .list > .pagerContent {
    margin-top: 40px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
    }
  }

  & > .listwrapper > .banner {
    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: banner;
    }
  }
`

const newsNavigationStyle = css`
  & > .body {
    margin-top: 64px;
    padding: 16px 24px 0;
    border-radius: 16px;
    background: var(--color-background-bright);

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }

  & > .body > .title {
    color: var(--color-background-key);
    font-size: rem(16px);
    font-weight: bold;
  }
`

export const query = graphql`
  query ($limit: Int!, $skip: Int!, $filter: MicrocmsStgNewsFilterInput!) {
    allFile(filter: { name: { eq: "cms-news" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    allMicrocmsStgNews(limit: $limit, skip: $skip, filter: $filter) {
      edges {
        node {
          category {
            name
          }
          date
          id
          image_catch {
            url
          }
          stgNewsId
          tags {
            name
            id
          }
          title
        }
      }
    }
  }
`

const NewsIndexPage = ({ data, pageContext }: NewsIndexProps) => {
  const isCategoryIndex = !!pageContext.categoryName && !!pageContext.categoryContentId
  const isTagIndex = !!pageContext.tagId && !!pageContext.tagName
  const breadcrumb = isCategoryIndex
    ? [
        {
          label: 'お役立ち情報',
          path: '/news',
        },
        {
          label: pageContext.categoryName,
        },
      ]
    : isTagIndex
    ? [
        {
          label: 'お役立ち情報',
          path: '/news',
        },
        {
          label: `#${pageContext.tagName}`,
        },
      ]
    : [
        {
          label: 'お役立ち情報',
        },
      ]

  const seo = {
    title: isCategoryIndex
      ? `${pageContext.categoryName} | お役立ち情報`
      : isTagIndex
      ? `#${pageContext.tagName} | お役立ち情報`
      : 'お役立ち情報',
  }

  const pager = {
    path: isCategoryIndex
      ? `/news/category/${pageContext.categoryContentId}`
      : isTagIndex
      ? `/news/tag/${pageContext.tagContentId}`
      : '/news',
    currentPage: pageContext.currentPage,
    totalPage: pageContext.totalPage,
  }

  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={newsIndexStyle}>
        <h1 className="title">
          {isCategoryIndex ? pageContext.categoryName : isTagIndex ? `#${pageContext.tagName}` : 'お役立ち情報'}
        </h1>
        <div className="listwrapper">
          <div className="list targrt-news-list">
            <ul>
              {data?.allMicrocmsStgNews.edges.map((edge) => {
                const item = edge.node
                if (!item || !item.image_catch || !item.image_catch.url) return
                const gatsbyImageData = getGatsbyImageData(data.allFile, item.image_catch.url)
                return <Item key={item.id} item={item} gatsbyImageData={gatsbyImageData} />
              })}
            </ul>
            <div className="pagerContent">
              <Pager pager={pager} />
            </div>
          </div>
          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">カテゴリ</p>
              <Category />
            </div>
          </aside>
          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">人気のタグ</p>
              <Tag />
            </div>
          </aside>
          <aside className={newsNavigationStyle}>
            <div className="body">
              <p className="title">おすすめ記事</p>
              <Recommend />
            </div>
          </aside>
          <aside className="banner news-banner">
            <Banner />
          </aside>
        </div>
      </main>
    </Layout>
  )
}

export default NewsIndexPage
